import React from "react";
import PropTypes from "prop-types";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import MediaBlock from "../components/organisms/media-block";

function DesignGuideItem({ props }) {
  const designProps = props;
  return (
    <div className="design__content design__content--media">
      <SEO title="Design System - Guide Item" />
      <h2>Guide Item</h2>
      <p className="design__text p2">
        Guide items describe content meant to guide a user through a process,
        like instructions or answers to questions.
      </p>
      <div className="design__stories">
        <DesignStory
          title="Guide Item"
          component={MediaBlock}
          type="media"
          props={designProps}
        />
      </div>
    </div>
  );
}

DesignGuideItem.propTypes = {
  props: PropTypes.objectOf(PropTypes.any),
};

DesignGuideItem.defaultProps = {
  props: {},
};

export default DesignGuideItem;
